import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../services/globals';

@Injectable({
  providedIn: 'root'
})
export class ElectricyBillService {

  constructor(
    private http: HttpClient
  ) { }

  getElectrictyBill(): Observable<any> {
    return this.http.get(baseUrl + '/electricity-bill/list/').pipe(
      map(res => {
        return res as any;
      }));
  }
}
