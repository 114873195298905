<section>
    <div class="container">
        <div class="shadow p-3 mb-5 bg-white rounded" data-aos="fade-up" data-aos-duration="3000">
            <div class="col-md-12 text-center">
                <h1>{{'electromechanical_works_page.electromechanical_works' | translate}}</h1>
            </div>
        </div>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="row align-items-center mb-5">
            <div class="col-md-6 pr-md-5 mb-5">
                <div class="block-41">
                    <div class="block-41-subheading d-flex">
                        <div class="block-41-number">01</div>
                        <div class="block-41-line align-self-center mx-2"></div>
                        <div class="block-41-subheading-2">
                            <h4>{{'electromechanical_works_page.water_desalination_title' | translate}}</h4>
                        </div>
                    </div>
                    <div class="block-41-text">
                        <p>{{'electromechanical_works_page.water_desalination_desc' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <img src="/assets/images/Grid-tied-system1.png" alt="Image" class="img-fluid"> -->
                <div class="gallery-wrapper">
                    <ngx-gallery [options]="galleryOptions" [images]="waterDesalinationImages" class="ngx-gallery">
                    </ngx-gallery>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section  bg-light">
    <div class="container">
        <div class="row align-items-center mb-5">
            <div class="col-md-6 pl-md-5 mb-5 order-md-2">
                <div class="block-41" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div class="block-41-subheading d-flex">
                        <div class="block-41-number">02</div>
                        <div class="block-41-line align-self-center mx-2"></div>
                        <div class="block-41-subheading-2">
                            <h4>{{'electromechanical_works_page.swimming_pools_title' | translate}}</h4>
                        </div>
                    </div>
                    <div class="block-41-text">
                        <p class="last-block">{{'electromechanical_works_page.swimming_pools_desc' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 order-md-1">
                <!-- <img data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                    src="/assets/images/solar-energy-office.jpg" alt="Image" class="img-fluid"> -->
                <div class="gallery-wrapper">
                    <ngx-gallery [options]="galleryOptionsSWimming" [images]="swimmingPoolImages" class="ngx-gallery">
                    </ngx-gallery>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="row align-items-center mb-5">
            <div class="col-md-7 pr-md-3 mb-5">
                <div class="block-41">
                    <div class="block-41-subheading d-flex">
                        <div class="block-41-number">03</div>
                        <div class="block-41-line align-self-center mx-2"></div>
                        <div class="block-41-subheading-2">
                            <h4>{{'electromechanical_works_page.production_lines_title' | translate}}</h4>
                        </div>
                    </div>
                    <div class="block-41-text">
                        <p>{{'electromechanical_works_page.production_lines_desc' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <!-- <img src="/assets/images/ongrid-indstrual.jpg" alt="Image" class="img-fluid"> -->
                <div class="gallery-wrapper">
                    <ngx-gallery [options]="galleryOptions" [images]="productionLinesImages" class="ngx-gallery">
                    </ngx-gallery>
                </div>
            </div>
        </div>
    </div>
</section>