import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Observable } from 'rxjs';
import { baseUrl } from '../services/globals';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {


  constructor(private http: HttpClient) { }

  listProjects(q): Observable<any> {
    return this.http.get(baseUrl + '/jobs/' + q + '/1/list').pipe(map(res => {
      return res as any;
    }));
  }

  viewProject(id): Observable<any> {
    return this.http.get(baseUrl + '/jobs/' + id).pipe(map(res => {
      return res as any;
    }));
  }
}
