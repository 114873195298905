<footer [ngClass]="'site-footer ' + currentLanguage" class="" role="contentinfo">
	<div class="container">
		<div class="row mb-5">
			<div class="col-md-4 mb-5">
				<img class="footer-logo" src="/assets/images/logo.png" alt="">
				<ul class="list-unstyled footer-link d-flex footer-social">
					<li><a target="_blank" href="https://twitter.com/EgySphinx?s=20" class="p-2"><span
								class="fa fa-twitter"></span></a>
					</li>
					<li><a target="_blank" href="https://www.facebook.com/EgySphinx-169691486984702/" class="p-2"><span
								class="fa fa-facebook"></span></a></li>
					<li><a target="_blank" href="https://www.linkedin.com/in/egysphinx-solutions-09029a165" class="p-2"><span
								class="fa fa-linkedin"></span></a></li>
					<li><a target="_blank" href="https://www.instagram.com/explore/locations/169691486984702/egysphinx/"
							class="p-2"><span class="fa fa-instagram"></span></a></li>
				</ul>

			</div>
			<div class="col-md-5 mb-5 pl-md-5">
				<h3>{{'footer.contact_info_1' | translate}}</h3>
				<ul class="list-unstyled footer-link">
					<li class="d-block">
						<span class="d-block">{{'footer.address' | translate}}:</span>
						<span class="text-white">{{'footer.street_1' | translate}}</span>
					</li>
					<li class="d-block"><span class="d-block">{{'footer.telephone' | translate}}:</span><span
							class="text-white">{{'footer.telephone_number_1' | translate}}</span></li>
					<li class="d-block"><span class="d-block">{{'footer.email' | translate}}:</span><span
							class="text-white">{{'footer.our_emial' | translate}}</span></li>
				</ul>
				<h3>{{'footer.contact_info_2' | translate}}</h3>
				<ul class="list-unstyled footer-link">
					<li class="d-block">
						<span class="d-block">{{'footer.address' | translate}}:</span>
						<span class="text-white">{{'footer.street_2' | translate}}</span>
					</li>
					<li class="d-block"><span class="d-block">{{'footer.telephone' | translate}}:</span><span
							class="text-white">{{'footer.telephone_number_2' | translate}}</span></li>
				</ul>
			</div>
			<div class="col-md-3 mb-5">
				<h3>{{'footer.quick_links' | translate}}</h3>
				<ul class="list-unstyled footer-link">
					<li><a routerLink="/about-us">{{'footer.about_us' | translate}}</a></li>
					<li><a routerLink="/contact-us">{{'footer.contact_us' | translate}}</a></li>
					<li><a routerLink="/on-grid-system">{{'footer.on_grid_system' | translate}}</a></li>
					<li><a routerLink="/off-grid-system">{{'footer.off_grid_system' | translate}}</a></li>
					<li><a routerLink="/electricity-bill">{{'footer.electricity_bill' | translate}}</a></li>

					<li><a routerLink="/projects">{{'footer.projects' | translate}}</a></li>
					<li><a routerLink="/electromechanical-works">{{'footer.electromechanical_works' | translate}}</a></li>
					<li><a routerLink="/general-procurement">{{'footer.general_procurement' | translate}}</a></li>

				</ul>
			</div>
			<div class="col-md-3">

			</div>
		</div>
		<div class="row">
			<div class="col-12 text-md-center text-left">
				<p>
					Copyright &copy;
					All rights reserved | 2020
				</p>
			</div>
		</div>
	</div>
</footer>