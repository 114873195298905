import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  optionsCertificates: NgxGalleryOptions[];
  ImagesCertificates: NgxGalleryImage[];

  optionsPartner: NgxGalleryOptions[];
  ImagesPartner: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.optionsCertificates = [
      {
        width: '900px',
        height: '900px',
        thumbnailsColumns: 0,
        thumbnails: false,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 900,
        width: '100%',
        height: '600',
        imagePercent: 80,
        thumbnailsPercent: 80,
        thumbnailsMargin: 80,
        thumbnailMargin: 80
      },
      // max-width 800
      {
        breakpoint: 800,
        preview: false
      }
    ];
    this.ImagesCertificates = [
      {
        small: '/assets/images/about-us/new_certifiaecte.jpeg',
        medium: '/assets/images/about-us/new_certifiaecte.jpeg',
        big: '/assets/images/about-us/new_certifiaecte.jpeg'
      }
    ];

    this.optionsPartner = [
      {
        width: '100%',
        height: '400px',
        imageAutoPlay: true,
        thumbnailsColumns: 6,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 800
      {
        breakpoint: 800,
        preview: false
      }
    ];
    this.ImagesPartner = [
      {
        small: '/assets/images/about-us/partner-1.jpeg',
        medium: '/assets/images/about-us/partner-1.jpeg',
        big: '/assets/images/about-us/partner-1.jpeg'
      },
      {
        small: '/assets/images/about-us/partner-2.jpeg',
        medium: '/assets/images/about-us/partner-2.jpeg',
        big: '/assets/images/about-us/partner-2.jpeg'
      },
      {
        small: '/assets/images/about-us/partner-3.jpeg',
        medium: '/assets/images/about-us/partner-3.jpeg',
        big: '/assets/images/about-us/partner-3.jpeg'
      },
      {
        small: '/assets/images/about-us/partner-4.jpeg',
        medium: '/assets/images/about-us/partner-4.jpeg',
        big: '/assets/images/about-us/partner-4.jpeg'
      },
      {
        small: '/assets/images/about-us/partner-5.jpeg',
        medium: '/assets/images/about-us/partner-5.jpeg',
        big: '/assets/images/about-us/partner-5.jpeg'
      },
      {
        small: '/assets/images/about-us/partner-6.jpeg',
        medium: '/assets/images/about-us/partner-6.jpeg',
        big: '/assets/images/about-us/partner-6.jpeg'
      },
      {
        small: '/assets/images/about-us/partner-7.jpeg',
        medium: '/assets/images/about-us/partner-7.jpeg',
        big: '/assets/images/about-us/partner-7.jpeg'
      },
      {
        small: '/assets/images/about-us/partner-8.jpg',
        medium: '/assets/images/about-us/partner-8.jpg',
        big: '/assets/images/about-us/partner-8.jpg'
      }
    ];
  }

}
