import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WatchStorageService } from '../../../services/watchstorage.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentLanguage: any;
  constructor(
    public translate: TranslateService,
    public watchStorage: WatchStorageService
  ) {
  }

  ngOnInit(): void {
    this.currentLanguage = localStorage.getItem('language');
    this.watchStorage.watchStorage().subscribe(changed => {
      if (changed) {
        this.currentLanguage = localStorage.getItem('language');
      }
    });
  }

}
