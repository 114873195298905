<section class="section" [ngClass]="currenLanguage === 'en' ? 'en' : 'ar'">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mb-5 order-2">
                <form [formGroup]="contactForm">
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label for="name">{{'contact_us.name' | translate}}</label>
                            <input type="text" id="name" class="form-control" formControlName="name" required>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="phone">{{'contact_us.phone' | translate}}</label>
                            <input type="text" id="phone" class="form-control" formControlName="phone" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label for="email">{{'contact_us.email' | translate}}</label>
                            <input type="email" id="email" class="form-control" formControlName="email" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label for="message">{{'contact_us.write_message' | translate}}</label>
                            <textarea name="message" id="message" class="form-control " cols="30" rows="8" formControlName="details" required></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <input type="submit" (click)="sendMsg()" value="{{'contact_us.send_message' | translate}}"
                                class="btn btn-primary px-3 py-3">
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-6 order-2 mb-5">
                <div class="row justify-content-right">
                    <div class="col-md-8 mx-auto contact-form-contact-info">
                        <p class="d-flex">
                            <span class="ion-ios-location icon mr-5"></span>
                            <span class="adderess">{{'contact_us.address' | translate}}</span>
                        </p>

                        <p class="d-flex">
                            <span class="ion-ios-telephone icon mr-5"></span>
                            <span class="adderess" dir="ltr">{{'contact_us.address_phone' | translate}}</span>
                        </p>

                        <p class="d-flex">
                            <span class="ion-android-mail icon mr-5"></span>
                            <span class="adderess">info@egysphinx.com</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>