<div class="applications-page">
    <div class="container" [ngClass]="currentLAng === 'ar' ? 'ar' : 'en'">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        {{'electricity_bill_page.title' | translate}}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{'electricity_bill_page.sub_title' | translate}}</h5>
                        <form>
                            <div class="form-group">
                                <div class="col-md-6 col-sm-12">
                                    <label
                                        for="usage">{{'electricity_bill_page.choose_governorate' | translate}}</label>
                                    <ngx-select [placeholder]="'electricity_bill_page.choose_governorate' | translate"
                                        [items]="governratesList"
                                        [noResultsFound]="'electricity_bill_page.notFound' | translate"></ngx-select>
                                </div>
                            </div>
                            
                            <div class="form-group form-check">
                                <label>{{'electricity_bill_page.type_of_use' | translate}}</label>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" (change)="checkType('home')"
                                            name="optradio">{{currentLAng === 'ar' ? '&nbsp;&nbsp;&nbsp;&nbsp;' : ''}}{{'electricity_bill_page.home_use' | translate}}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" (change)="checkType('shop')"
                                            name="optradio">{{currentLAng === 'ar' ? '&nbsp;&nbsp;&nbsp;&nbsp;' : ''}}{{'electricity_bill_page.shops' | translate}}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" (change)="checkType('industrial')"
                                            name="optradio">{{currentLAng === 'ar' ? '&nbsp;&nbsp;&nbsp;&nbsp;' : ''}}{{'electricity_bill_page.industrial' | translate}}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" (change)="checkType('irrigation')"
                                            name="optradio">{{currentLAng === 'ar' ? '&nbsp;&nbsp;&nbsp;&nbsp;' : ''}}{{'electricity_bill_page.irrigation' | translate}}
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="usage">{{'electricity_bill_page.enter_usage' | translate}}</label>
                                <div class="col-md-3 col-sm-12">
                                    <input type="number" class="form-control" id="usage" aria-describedby="usageHelp"
                                        placeholder="{{'electricity_bill_page.usage_placeholder' | translate}}"
                                        (input)="setUsageValue($event.target.value)">
                                    <small id="usageHelp"
                                        class="form-text text-muted">{{'electricity_bill_page.from' | translate}}
                                        1-100000</small>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="typeOfUse === 'industrial'">
                                <label for="usage">{{'electricity_bill_page.contractual_capacity' | translate}}</label>
                                <div class="col-md-3 col-sm-12">
                                    <input type="number" class="form-control" id="usage" aria-describedby="usageHelp"
                                        placeholder="{{'electricity_bill_page.contractual_capacity' | translate}}"
                                        (input)="setContractualCapacityValue($event.target.value)">
                                    <small id="usageHelp"
                                        class="form-text text-muted">{{'electricity_bill_page.from' | translate}}
                                        1-100000</small>
                                </div>
                            </div>
                            <button class="btn btn-primary" (click)="calculateBill()"
                                type="button">{{'electricity_bill_page.calulate_usage' | translate}}
                                <span *ngIf="isCalculating" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                            </button>
                        </form>
                        <div class="row result" *ngIf="!isCalculating && totalPrice">
                            <div class="col-md-12">
                                <table class="table table-striped table-bordered">
                                    <tbody>
                                        <tr>
                                            <th scope="row">{{'electricity_bill_page.type_of_use' | translate}}</th>
                                            <td *ngIf="typeOfUse === 'home'">
                                                {{ 'electricity_bill_page.home_use' | translate }}
                                            </td>
                                            <td *ngIf="typeOfUse === 'shops'">
                                                {{ 'electricity_bill_page.home_use' | translate }}
                                            </td>
                                            <td *ngIf="typeOfUse === 'industrial'">
                                                {{ 'electricity_bill_page.home_use' | translate }}
                                            </td>
                                            <td *ngIf="typeOfUse === 'irrigation'">
                                                {{ 'electricity_bill_page.home_use' | translate }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{'electricity_bill_page.monthly_usage' | translate}}</th>
                                            <td>{{showableUsage}} {{'electricity_bill_page.kilowatt' | translate}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{'electricity_bill_page.customer_service' | translate}}
                                            </th>
                                            <td>{{customerService}} {{'electricity_bill_page.pound' | translate}}
                                            </td>
                                        </tr>
                                        <tr class="bg-success result-row">
                                            <th scope="row">{{'electricity_bill_page.total_required' | translate}}</th>
                                            <td>{{totalPrice.toFixed(2)}} {{'electricity_bill_page.pound' | translate}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12" *ngIf="totalPrice < 800">
                                <div class="alert alert-primary" role="alert">
                                    {{'electricity_bill_page.result_msg_not_create' | translate}}
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="totalPrice >= 800">
                                <div class="alert alert-success" role="alert">
                                    {{'electricity_bill_page.result_msg_create' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>