import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../services/globals';

@Injectable({
    providedIn: 'root'
})
export class ContactusService {

    constructor(
        private http: HttpClient
    ) { }

    addContactMsg(body): Observable<any> {
        return this.http.post(baseUrl + '/contactus/', body).pipe(
            map(res => {
                return res as any;
            }));
    }
}
