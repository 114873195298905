import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from '../../../services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { WatchStorageService } from '../../../services/watchstorage.service';
import { baseUrl } from '../../../services/globals';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.css']
})
export class ProjectViewComponent implements OnInit {
  project: any;
  id: any;
  currenLanguage = 'en';
  imgUrl: any = baseUrl + '/uploads/';
  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    private api: ProjectsService,
    private toastr: ToastrService,
    public watchStorage: WatchStorageService
  ) {
    this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.loadProject();
  }

  ngOnInit(): void {
    this.watchStorage.watchStorage().subscribe(changed => {
      if (changed) {
        this.currenLanguage = localStorage.getItem('language');
      }
    });
  }


  loadProject() {
    this.api.viewProject(this.id).subscribe(res => {
      if (res.case === 1) {
        this.currenLanguage = localStorage.getItem('language');
        this.project = res.data;
      } else if (res.data === 0) {
        this.toastr.error('هناك خطاء');
      } else {
        this.toastr.error('هناك خطاء');
      }


    });
  }

}
