<nav class="navbar navbar-expand-lg navbar-light main-header">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/">
            <!-- <span class="elite">{{'header.egy' | translate}}</span>
            <span>{{' '}}</span>
            <span class="core">{{'header.Sphinx' | translate}}</span> -->
            <img class="header-logo" src="/assets/images/logo.png" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainnav"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="mainnav">
            <ul class="navbar-nav " [ngClass]="currentLAng === 'en' ? 'ml-auto' : 'mr-auto'">
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" routerLink="/">{{'header.home' | translate}}</a>
                </li>
                <li ngbDropdown class="nav-item">
                    <a href (click)="false" class="nav-link" ngbDropdownToggle>{{'header.solar_energy' | translate}}</a>
                    <div ngbDropdownMenu>
                        <button ngbDropdownItem data-toggle="collapse" data-target=".navbar-collapse.show"
                            routerLink="/on-grid-system">{{'header.on_grid_system' | translate}}</button>
                        <button ngbDropdownItem data-toggle="collapse" data-target=".navbar-collapse.show"
                            routerLink="/off-grid-system">{{'header.off_grid_system' | translate}}</button>
                    </div>
                </li>
                <li ngbDropdown class="nav-item">
                    <a href (click)="false" class="nav-link" ngbDropdownToggle>{{'header.services' | translate}}</a>
                    <div ngbDropdownMenu>
                        <button ngbDropdownItem data-toggle="collapse" data-target=".navbar-collapse.show"
                            routerLink="/electromechanical-works">{{'header.electromechanical_works' |
                            translate}}</button>
                        <button ngbDropdownItem data-toggle="collapse" data-target=".navbar-collapse.show"
                            routerLink="/general-procurement">{{'header.general_procurement' | translate}}</button>
                    </div>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" routerLink="/electricity-bill">{{'header.electricity_bill' | translate}}<span
                            class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" routerLink="/projects">{{'header.projects' | translate}}<span
                            class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" routerLink="/contact-us">{{'header.contact_us' | translate}}<span
                            class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" routerLink="/about-us">{{'header.about_us' | translate}}<span
                            class="sr-only">(current)</span></a>
                </li>
                <li>
                    <span class="form-inline">
                        <select class="form-control" #selectedLang (change)="switchLang(selectedLang.value)">
                            <option *ngFor="let language of translate.getLangs()" [value]="language"
                                [selected]="language === translate.currentLang" data-toggle="collapse"
                                data-target=".navbar-collapse.show">
                                {{ language }}
                            </option>
                        </select>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</nav>