<div class="project">
  <div class="container">
    <div class="row" *ngIf="project">
      <div class="col-md">
        <div class="card mb-4">
          <img class="card-img-top" src="{{imgUrl}}{{project.photos}}" alt="Card image cap">
          <div class="card-body">
            <h5 *ngIf="currenLanguage === 'en'" class="card-title">{{project.name_en}}</h5>
            <h5  *ngIf="currenLanguage === 'ar'" class="card-title">{{project.name_ar}}</h5>
            <h6 class="text-muted">{{project.date | date}}</h6>
            <p *ngIf="currenLanguage === 'en'" class="card-text">{{project.details_en}}</p>
            <p *ngIf="currenLanguage === 'ar'" class="card-text">{{project.details_ar}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
