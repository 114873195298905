<!-- Start Welcome -->
<div class="welcome">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-md-6 p-0">
                <div class="head pl-4">
                    <h2>{{'about_us_page.welcome_to_egySpinx' | translate}}
                    </h2>
                    <h3>{{'about_us_page.wecome_h3_1' | translate}}</h3>
                    <h3>{{'about_us_page.wecome_h3_2' | translate}}</h3>
                    <p>{{'about_us_page.wecome_p_1' | translate}}</p>
                    <!-- <p>{{'about_us_page.wecome_p_2' | translate}}</p> -->
                    <!-- <p>{{'about_us_page.wecome_p_3' | translate}}</p>
          <p>{{'about_us_page.wecome_p_4' | translate}}</p> -->
                </div>
            </div>
            <div class="col-md-6 p-0">
                <img src="/assets/images/about-us/welcome_section.JPG" class="w-100
          custom-img" alt="" />
            </div>
        </div>
    </div>
</div>
<!-- end Welcome -->


<!-- Start experience -->
<div class="experience mb-4">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-md-6 p-0">
                <img src="/assets/images/about-us/second_section.jfif" class="w-100
          custom-img" alt="" />
            </div>
            <div class="col-md-6 p-2">
                <div class="head pl-4 pr-4">
                    <h2>{{'about_us_page.our_vision' | translate}}</h2>
                    <h3>{{'about_us_page.experience_h3_1' | translate}}</h3>
                    <h2>{{'about_us_page.our_mission' | translate}}</h2>
                    <p>{{'about_us_page.experience_p_1' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End experience -->





<!-- Start discove -->
<div class="discove">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mt-4">
                <img src="/assets/images/about-us/third_section.jpg" class="w-100 h-100" alt="" />
            </div>
            <div class="col-md-6 mt-4">
                <h2>{{'about_us_page.solar_energy_services' | translate}}
                </h2>
                <p>{{'about_us_page.site_survey' | translate}}</p>
                <p>{{'about_us_page.systems_upgrade' | translate}}</p>
                <p>{{'about_us_page.charging_stations' | translate}}</p>
                <p>{{'about_us_page.On_off_grid' | translate}}</p>
                <p>{{'about_us_page.system_installation' | translate}}</p>
                <p>{{'about_us_page.equipment_maintenance' | translate}}</p>
                <p>{{'about_us_page.solar_pumping_stations' | translate}}</p>
                <p>{{'about_us_page.B2b_commercial_transactions' | translate}}</p>
                <p>{{'about_us_page.Building_integrated_photovoltaic' | translate}}</p>
                <a class="text-uppercase text-decoration-none d-inline-block mt-1" routerLink="/on-grid-system">{{'about_us_page.discover_on_grid_system'
          | translate}}</a>
            </div>
        </div>
    </div>
</div>
<!-- End discove -->



<!-- start story -->
<div class="story">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mt-4">
                <div class="head">
                    <h2>{{'about_us_page.solar_water_heaters' | translate}}
                    </h2>
                    <p>{{'about_us_page.water_heates_p_1' | translate}}</p>
                    <p>{{'about_us_page.water_heates_p_2' | translate}}</p>
                    <p>{{'about_us_page.water_heates_p_3' | translate}}</p>
                    <p>{{'about_us_page.water_heates_p_4' | translate}}</p>
                    <p>{{'about_us_page.water_heates_p_5' | translate}}</p>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <img src="/assets/images/about-us/fourth_section.jpg" class="w-100
          h-100" alt="" />
            </div>
        </div>
    </div>
</div>
<!-- end story -->
<!-- 
<div class="royal">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mt-4">
                <h2 class="Certificates text-center">{{'about_us_page.certificates'| translate}}
                </h2>
            </div>
            <div class="col-md-12">
                <div class="gallery-wrapper">
                    <ngx-gallery [options]="optionsCertificates" [images]="ImagesCertificates" class="ngx-gallery">
                    </ngx-gallery>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="royal">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mt-4">
                <h2 class="Certificates text-center">{{'about_us_page.new_cerificate'| translate}}
                </h2>
            </div>
            <div class="col-md-12">
                <!-- <img src="/assets/images/about-us/new_certifiaecte.jpeg" alt=""> -->
                <div class="gallery-wrapper">
                    <ngx-gallery [options]="optionsCertificates" [images]="ImagesCertificates" class="ngx-gallery">
                    </ngx-gallery>
            </div>
        </div>
    </div>
</div>

<div class="royal">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mt-4">
                <h2 class="Certificates text-center">{{'about_us_page.partners'| translate}}
                </h2>
            </div>
            <div class="col-md-12">
                <div class="gallery-wrapper-2">
                    <ngx-gallery [options]="optionsPartner" [images]="ImagesPartner" class="ngx-gallery">
                    </ngx-gallery>
                </div>
            </div>
        </div>
    </div>
</div>