<ngb-carousel>
  <!-- coming soon -->
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="/assets/images/slider/slider-0.jpg" alt="Random zero slide">
    </div>
    <div class="carousel-caption">
      <h3>{{'about_us_page.coming_soon' | translate}}</h3>
      <p> ­ </p>
    </div>
  </ng-template>
  <!-- welcome to egysphinx -->
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="/assets/images/slider/slider-1.jpg" alt="Random first slide">
    </div>
    <div class="carousel-caption">
      <h3>{{'about_us_page.welcome_to_egySpinx' | translate}}</h3>
      <p>{{'about_us_page.wecome_h3_1' | translate}}</p>
    </div>
  </ng-template>
  <!-- our vision -->
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="/assets/images/slider/slider-2.jpg" alt="Random second slide">
    </div>
    <div class="carousel-caption">
      <h3>{{'about_us_page.our_vision' | translate}}</h3>
      <p>{{'about_us_page.experience_h3_1' | translate}}</p>
    </div>
  </ng-template>
  <!-- our mission -->
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="/assets/images/slider/slider-3.jpg" alt="Random third slide">
    </div>
    <div class="carousel-caption">
      <h3>{{'about_us_page.our_mission' | translate}}</h3>
      <p>{{'about_us_page.experience_p_1' | translate}}</p>
    </div>
  </ng-template>
</ngb-carousel>

<div class="feature text-center mt-4">
  <div class="container">
    <div class="row images-section">
      <div class="col-sm-12 col-lg-4" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <img class="img-1" src="./assets/images/section_2_homepage/solar energy.png" />
        <h3 class="p-4">{{'home_page.solar_energy' | translate}}</h3>
      </div>
      <div class="col-sm-12 col-lg-4" data-aos="flip-in" data-aos-easing="ease-in-sine">
        <img class="img-2" src="./assets/images/section_2_homepage/Electromechanical works.png" />
        <h3 class="p-4">{{'home_page.electromechanical_works' | translate}}</h3>
      </div>
      <div class="col-sm-12 col-lg-4" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <img class="img-3" src="./assets/images/section_2_homepage/General Procurement.png" />
        <h3 class="p-4">{{'home_page.general_procurement' | translate}}</h3>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid text-center">
  <div class="row">
    <iframe width="100%" height="534" src="https://www.youtube.com/embed/oiv5NMJALPE?autoplay=1&mute=1&enablejsapi=1"
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<!-- start overview -->
<div class="overview text-center">
  <div class="container">
    <h2>{{'home_page.company_overview' | translate}}</h2>
    <p>{{'home_page.about_decs' | translate}}</p>
    <h4>{{'home_page.lets_start' | translate}}</h4>
    <button routerLink="/about-us"><span>{{'home_page.all_about_egysphinx' | translate}}</span></button>
  </div>
</div>
<!-- end overview -->


<!-- start latest posts -->

<div class="latest-posts">
  <div class="container">
    <h2 class="text-center">{{ "projects_list.title" | translate }}</h2>
    <!-- <p class="latestP text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore repellendus
            aut reiciendis ipsum beatae eos facilis quibusdam quis voluptatum, magnam a. Minus dolorum praesentium eum
            aperiam magnam numquam quo libero!</p> -->

    <div class="row">
      <div class="col-md-4" *ngFor="let project of projects">
        <div class="card-wrapper">
          <div class="card">
            <div class="card-img-wrapper">
              <img src="{{imgUrl}}{{project.photos}}" class="card-img-top" alt="..." />
            </div>
            <div class="card-body text-">
              <h5 *ngIf="currenLanguage === 'en'" class="card-title">{{ project.name_en }}</h5>
              <h5 *ngIf="currenLanguage === 'ar'" class="card-title">{{ project.name_ar }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ project.date | date }}</h6>
              <span *ngIf="currenLanguage === 'en'" class="texts">{{project.details_en}}</span>
              <span *ngIf="currenLanguage === 'ar'" class="texts">{{project.details_ar}}</span>
              <div class="card-content">
                <a [routerLink]="['/project', project._id]" class="card-link">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end latest posts -->
