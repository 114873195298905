<div class="container">
    <h5 class="section-title h1">{{'general_procurement_page.control_title' | translate}}</h5>
    <div class="row">
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/inductive_sensors.jpeg" />
                </div>
                <div class="card-inner">
                    <div class="header">
                        <h4>{{'general_procurement_page.inductive_sensors' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/pressure-sensor.jpg" />
                </div>
                <div class="card-inner">
                    <div class="header">
                        <h4>{{'general_procurement_page.pressure_sensors' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/Photoelectric-Sensors.jpg" />
                </div>
                <div class="card-inner">
                    <div class="header">
                        <h4>{{'general_procurement_page.photoelectric_sensors' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/FlowSensor.jpg" />
                </div>
                <div class="card-inner">
                    <div class="header">
                        <h4>{{'general_procurement_page.flow_sensors' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/capacitive-senserors.jpeg" />
                </div>
                <div class="card-inner">
                    <div class="header">
                        <h4>{{'general_procurement_page.capacitive_sensors' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/tempresure-sensor.jpg" />
                </div>
                <div class="card-inner">
                    <div class="header">
                        <h4>{{'general_procurement_page.temperature_sensors' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h5 class="section-title h1">{{'general_procurement_page.valve' | translate}}</h5>
    <h5>{{'general_procurement_page.solenoid_valves' | translate}}</h5>
    <div class="row">
        <div class="col-sm-3">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/Solenoid-Valve-1.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/Solenoid-Valve-2.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/Solenoid-Valve-3.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/Solenoid-Valve-4.webp" />
                </div>
            </div>
        </div>
    </div>
    <h5>{{'general_procurement_page.automation' | translate}}</h5>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/Automation.jpg" />
                </div>
            </div>
        </div>
    </div>
    <h5 class="section-title h1">{{'general_procurement_page.servo_motors' | translate}}</h5>
    <div class="row justify-content-center">
        <div class="col-sm-2">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/servo-motor-1.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-2">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/servo-motor-2.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-2">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/servo-motor-3.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-2">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/servo-motor-4.jpeg" />
                </div>
            </div>
        </div>
        <div class="col-sm-2">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/servo-motor-5.jpg" />
                </div>
            </div>
        </div>
    </div>
    <h5 class="section-title h1">{{'general_procurement_page.ac_Motors_gear_morors' | translate}}</h5>
    <div class="row justify-content-center">
        <div class="col-sm-3">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/ac-motor.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/ac-motor-2.jpg" />
                </div>
            </div>
        </div>
    </div>
    <h5 class="section-title h1">{{'general_procurement_page.pump' | translate}}</h5>
    <div class="row justify-content-center">
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/pump-motor-1.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/pump-motor-2.jpg" />
                </div>
            </div>
        </div>
    </div>
    <h5 class="section-title h1">{{'general_procurement_page.filters' | translate}}</h5>
    <div class="row justify-content-center">
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/filters.jpg" />
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
                <div class="image">
                    <img src="/assets/images/General_procurement/filters-2.jpg" />
                </div>
            </div>
        </div>
    </div>
</div>