import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-general-procurement',
  templateUrl: './general-procurement.component.html',
  styleUrls: ['./general-procurement.component.scss']
})
export class GeneralProcurementComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  Images: NgxGalleryImage[];
  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      { image: false, thumbnails: false, width: '0px', height: '0px' },
      { breakpoint: 500, width: '300px', height: '300px', thumbnailsColumns: 3 },
      { breakpoint: 300, width: '100%', height: '200px', thumbnailsColumns: 2 }
      ];
    this.Images = [
      {
        small: '/assets/images/production_lines/1.jpg',
        medium: '/assets/images/production_lines/1.jpg',
        big: '/assets/images/production_lines/1.jpg'
      },
      {
        small: '/assets/images/production_lines/2.jpg',
        medium: '/assets/images/production_lines/2.jpg',
        big: '/assets/images/production_lines/2.jpg'
      },
      {
        small: '/assets/images/production_lines/3.jpg',
        medium: '/assets/images/production_lines/3.jpg',
        big: '/assets/images/production_lines/3.jpg'
      },
      {
        small: '/assets/images/production_lines/4.jpg',
        medium: '/assets/images/production_lines/4.jpg',
        big: '/assets/images/production_lines/4.jpg'
      },
      {
        small: '/assets/images/production_lines/5.jpg',
        medium: '/assets/images/production_lines/5.jpg',
        big: '/assets/images/production_lines/5.jpg'
      },
      {
        small: '/assets/images/production_lines/6.jpg',
        medium: '/assets/images/production_lines/6.jpg',
        big: '/assets/images/production_lines/6.jpg'
      },
      {
        small: '/assets/images/production_lines/7.jpg',
        medium: '/assets/images/production_lines/7.jpg',
        big: '/assets/images/production_lines/7.jpg'
      }
    ];
  }

}
