import { Component, OnInit } from '@angular/core';
import { WatchStorageService } from '../../services/watchstorage.service';

@Component({
  selector: 'app-containter',
  templateUrl: './containter.component.html',
  styleUrls: ['./containter.component.css']
})
export class ContainterComponent implements OnInit {
  currenLanguage = 'en';

  constructor(
    public watchStorage: WatchStorageService
  ) { }

  ngOnInit(): void {
    this.watchStorage.watchStorage().subscribe(changed => {
      if (changed) {
        this.currenLanguage = localStorage.getItem('language');
      }
    });
  }
}
