import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsService } from '../../services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { WatchStorageService } from '../../services/watchstorage.service';
import { baseUrl } from '../../services/globals';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  projects: any;
  currenLanguage = 'en';
  query = 3;
  imgUrl: any = baseUrl + '/uploads/';
  constructor(
    config: NgbCarouselConfig,
    public translate: TranslateService,
    private api: ProjectsService,
    private toastr: ToastrService,
    public watchStorage: WatchStorageService
  ) {
    config.interval = 6000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = false;
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    this.loadProjects();

  }
  ngOnInit(): void {
    this.watchStorage.watchStorage().subscribe(changed => {
      if (changed) {
        this.currenLanguage = localStorage.getItem('language');
      }
    });
  }

  loadProjects() {
    this.api.listProjects(this.query).subscribe(res => {
      if (!res.data.docs.length) {
      } else {
        if (res.case === 1) {
          this.currenLanguage = localStorage.getItem('language');
          this.projects = res.data.docs;
          console.log(this.projects);
        } else if (res.case === 0) {
        } else {
        }
      }
    });
  }

}
