import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from '../../../services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { WatchStorageService } from '../../../services/watchstorage.service';
import { baseUrl } from '../../../services/globals';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.css']
})
export class ProjectsListComponent implements OnInit {
  projects: any;
  currenLanguage = 'en';
  imgUrl: any = baseUrl + '/uploads/';

  constructor(
    public translate: TranslateService,
    private api: ProjectsService,
    private toastr: ToastrService,
    public watchStorage: WatchStorageService
    ) {
      this.loadProjects();
  }

  ngOnInit(): void {
    this.watchStorage.watchStorage().subscribe(changed => {
      if (changed) {
        this.currenLanguage = localStorage.getItem('language');
      }
    });
  }

  loadProjects(){
    this.api.listProjects(100000).subscribe(res => {
      if ( !res.data.docs.length){
        this.toastr.info('لا يوجد اي بيانات');
      } else {
        if ( res.case === 1){
          this.currenLanguage = localStorage.getItem('language');
          this.projects = res.data.docs;
        } else if (res.case === 0) {
          this.toastr.error('هناك خطاء');
        } else {
          this.toastr.error('هناك خطاء');
        }
      }
    });
  }

}
