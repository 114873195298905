import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactusService } from 'src/app/services/contactus.service';
import { WatchStorageService } from 'src/app/services/watchstorage.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  currenLanguage: string;
  contactForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private contactService: ContactusService,
    private watchStorage: WatchStorageService
  ) { }
  images = [700, 533, 807, 124].map((n) => `https://picsum.photos/id/${n}/900/500`);
  ngOnInit(): void {
    this.currenLanguage = localStorage.getItem('language');
    this.watchStorage.watchStorage().subscribe(changed => {
      if (changed) {
        this.currenLanguage = localStorage.getItem('language');
      }
    });
    this.buildContactForm();

  }
  buildContactForm() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      details: ['', Validators.required],
    });
  }
  sendMsg() {
    if (!this.contactForm.valid) {
      this.toastr.info('يجب اكمال الحقول المطلوبة');
      return;
    }
    const body = this.contactForm.value;
    this.contactService.addContactMsg(body).subscribe(
      response => {
        if (response.case === 1) {
          this.toastr.success('تم إرسال رسالتك بنجاح');
          this.router.navigate(['/']);
        }
      }
    );
  }
}
