import { NgModule } from '@angular/core';
import { appRoutes } from './app.routes';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GovernratesService } from './services/governrates.service';
import { WatchStorageService } from './services/watchstorage.service';
import { ContactusService } from 'src/app/services/contactus.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ElectricyBillService } from './services/electricy-bill.service';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderComponent } from './components/common/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ContainterComponent } from './components/containter/containter.component';
import { ProjectViewComponent } from './components/projects/project-view/project-view.component';
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ElectricityBillComponent } from './components/electricity-bill/electricity-bill.component';
import { OnGridSystemComponent } from './components/solar-energy/on-grid-system/on-grid-system.component';
import { OffGridSystemComponent } from './components/solar-energy/off-grid-system/off-grid-system.component';
import { GeneralProcurementComponent } from './components/our-services/general-procurement/general-procurement.component';
import { ElectromechanicalWorksComponent } from './components/our-services/electromechanical-works/electromechanical-works.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    ContactUsComponent,
    OnGridSystemComponent,
    OffGridSystemComponent,
    ElectricityBillComponent,
    AboutUsComponent,
    ContainterComponent,
    GeneralProcurementComponent,
    ElectromechanicalWorksComponent,
    ProjectsListComponent,
    ProjectViewComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    HttpClientModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSelectModule,
    NgxGalleryModule
  ],
  providers: [
    WatchStorageService,
    GovernratesService,
    ElectricyBillService,
    ContactusService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
