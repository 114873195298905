import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WatchStorageService } from '../../../services/watchstorage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  textDir = 'ltr';
  currentLAng = 'en';
  constructor(
    public translate: TranslateService,
    public watchStorage: WatchStorageService
  ) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    this.translate.onLangChange.subscribe((event) => {
      this.textDir = event.lang === 'ar' ? 'rtl' : 'ltr';
    });
  }

  ngOnInit(): void {
    this.watchStorage.setItem('language', 'en');
  }
  switchLang(lang: string) {
    this.currentLAng = lang;
    this.translate.use(lang);
    this.watchStorage.setItem('language', lang);
  }
}
