import { Routes } from '@angular/router';
import { TeamModule } from './team/team.module';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ContainterComponent } from './components/containter/containter.component';
import { ProjectViewComponent } from './components/projects/project-view/project-view.component';
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ElectricityBillComponent } from './components/electricity-bill/electricity-bill.component';
import { OnGridSystemComponent } from './components/solar-energy/on-grid-system/on-grid-system.component';
import { OffGridSystemComponent } from './components/solar-energy/off-grid-system/off-grid-system.component';
import { GeneralProcurementComponent } from './components/our-services/general-procurement/general-procurement.component';
import { ElectromechanicalWorksComponent } from './components/our-services/electromechanical-works/electromechanical-works.component';
export const appRoutes: Routes = [
    {
        path: 'team',
        loadChildren: './team/team.module#TeamModule'
    },
    {
        path: '',
        component: ContainterComponent,
        children: [
            {
                path: '',
                component: HomepageComponent
            },
            {
                path: 'about-us',
                component: AboutUsComponent
            },
            {
                path: 'contact-us',
                component: ContactUsComponent
            },
            {
                path: 'electricity-bill',
                component: ElectricityBillComponent
            },
            {
                path: 'on-grid-system',
                component: OnGridSystemComponent
            },
            {
                path: 'off-grid-system',
                component: OffGridSystemComponent
            },
            {
                path: 'projects',
                component: ProjectsListComponent
            },
            {
                path: 'project/:id',
                component: ProjectViewComponent
            },
            {
                path: 'general-procurement',
                component: GeneralProcurementComponent
            },
            {
                path: 'electromechanical-works',
                component: ElectromechanicalWorksComponent
            }

        ]
    }
];
