import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'EgySphinx';
  textDir = 'ltr';
  currentLAng = 'en';
  constructor(
    public translate: TranslateService,
    private router: Router
  ) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    this.translate.onLangChange.subscribe((event) => {
      this.textDir = event.lang === 'ar' ? 'rtl' : 'ltr';
    });
  }
  ngOnInit() {
    AOS.init();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  switchLang(lang: string) {
    this.currentLAng = lang;
    this.translate.use(lang);
  }
}
