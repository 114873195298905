import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-electromechanical-works',
  templateUrl: './electromechanical-works.component.html',
  styleUrls: ['./electromechanical-works.component.scss']
})
export class ElectromechanicalWorksComponent implements OnInit {
  constructor() { }
  galleryOptions: NgxGalleryOptions[];
  galleryOptionsSWimming: NgxGalleryOptions[];
  productionLinesImages: NgxGalleryImage[];
  waterDesalinationImages: NgxGalleryImage[];
  swimmingPoolImages: NgxGalleryImage[];
  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '100%',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
    this.galleryOptionsSWimming = [
      {
        width: '500px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.waterDesalinationImages = [
      {
        small: '/assets/images/water_desalination/1.JPG',
        medium: '/assets/images/water_desalination/1.JPG',
        big: '/assets/images/water_desalination/1.JPG'
      },
      {
        small: '/assets/images/water_desalination/2.JPG',
        medium: '/assets/images/water_desalination/2.JPG',
        big: '/assets/images/water_desalination/2.JPG'
      },
      {
        small: '/assets/images/water_desalination/3.jpg',
        medium: '/assets/images/water_desalination/3.jpg',
        big: '/assets/images/water_desalination/3.jpg'
      },
      {
        small: '/assets/images/water_desalination/4.JPG',
        medium: '/assets/images/water_desalination/4.JPG',
        big: '/assets/images/water_desalination/4.JPG'
      },
      {
        small: '/assets/images/water_desalination/5.jpg',
        medium: '/assets/images/water_desalination/5.jpg',
        big: '/assets/images/water_desalination/5.jpg'
      },
      {
        small: '/assets/images/water_desalination/6.jpg',
        medium: '/assets/images/water_desalination/6.jpg',
        big: '/assets/images/water_desalination/6.jpg'
      },
      {
        small: '/assets/images/water_desalination/7.jpg',
        medium: '/assets/images/water_desalination/7.jpg',
        big: '/assets/images/water_desalination/7.jpg'
      },
      {
        small: '/assets/images/water_desalination/8.jpg',
        medium: '/assets/images/water_desalination/8.jpg',
        big: '/assets/images/water_desalination/8.jpg'
      }
    ];
    this.swimmingPoolImages = [
      {
        small: '/assets/images/swimming_pool/1.jpg',
        medium: '/assets/images/swimming_pool/1.jpg',
        big: '/assets/images/swimming_pool/1.jpg'
      },
      {
        small: '/assets/images/swimming_pool/2.jpg',
        medium: '/assets/images/swimming_pool/2.jpg',
        big: '/assets/images/swimming_pool/2.jpg'
      },
      {
        small: '/assets/images/swimming_pool/3.jpg',
        medium: '/assets/images/swimming_pool/3.jpg',
        big: '/assets/images/swimming_pool/3.jpg'
      },
      {
        small: '/assets/images/swimming_pool/4.jpg',
        medium: '/assets/images/swimming_pool/4.jpg',
        big: '/assets/images/swimming_pool/4.jpg'
      },
      {
        small: '/assets/images/swimming_pool/5.jpg',
        medium: '/assets/images/swimming_pool/5.jpg',
        big: '/assets/images/swimming_pool/5.jpg'
      },
      {
        small: '/assets/images/swimming_pool/6.jpg',
        medium: '/assets/images/swimming_pool/6.jpg',
        big: '/assets/images/swimming_pool/6.jpg'
      },
      {
        small: '/assets/images/swimming_pool/7.jpg',
        medium: '/assets/images/swimming_pool/7.jpg',
        big: '/assets/images/swimming_pool/7.jpg'
      },
      {
        small: '/assets/images/swimming_pool/8.jpg',
        medium: '/assets/images/swimming_pool/8.jpg',
        big: '/assets/images/swimming_pool/8.jpg'
      }
    ];
    this.productionLinesImages = [
      {
        small: '/assets/images/production_lines/1.jpg',
        medium: '/assets/images/production_lines/1.jpg',
        big: '/assets/images/production_lines/1.jpg'
      },
      {
        small: '/assets/images/production_lines/2.jpg',
        medium: '/assets/images/production_lines/2.jpg',
        big: '/assets/images/production_lines/2.jpg'
      },
      {
        small: '/assets/images/production_lines/3.jpg',
        medium: '/assets/images/production_lines/3.jpg',
        big: '/assets/images/production_lines/3.jpg'
      },
      {
        small: '/assets/images/production_lines/4.jpg',
        medium: '/assets/images/production_lines/4.jpg',
        big: '/assets/images/production_lines/4.jpg'
      },
      {
        small: '/assets/images/production_lines/5.jpg',
        medium: '/assets/images/production_lines/5.jpg',
        big: '/assets/images/production_lines/5.jpg'
      },
      {
        small: '/assets/images/production_lines/6.jpg',
        medium: '/assets/images/production_lines/6.jpg',
        big: '/assets/images/production_lines/6.jpg'
      },
      {
        small: '/assets/images/production_lines/7.jpg',
        medium: '/assets/images/production_lines/7.jpg',
        big: '/assets/images/production_lines/7.jpg'
      }
    ];
  }
}
