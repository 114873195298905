import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ElectricyBillService } from '../../services/electricy-bill.service';
import { GovernratesService } from '../../services/governrates.service';

@Component({
  selector: 'app-electricity-bill',
  templateUrl: './electricity-bill.component.html',
  styleUrls: ['./electricity-bill.component.scss']
})
export class ElectricityBillComponent implements OnInit {
  monthlyUsage: number;
  typeOfUse: string;
  currentLAng = 'en';
  totalPrice: number;
  governratesList: any;
  customerService: any;
  isCalculating = false;
  showableUsage: number;
  electricityBillList: any;
  ContractualCapacity: any;
  constructor(
    private toastr: ToastrService,
    private gs: GovernratesService,
    public translate: TranslateService,
    private elecBill: ElectricyBillService
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.currentLAng = event.lang;
      this.buildGov();
    });
  }

  ngOnInit(): void {
    this.buildGov();
    this.loadElectricyBill();
  }
  loadElectricyBill() {
    this.elecBill.getElectrictyBill().subscribe(
      response => {
        if (response.case === 1) {
          this.electricityBillList = response.data;
        }
      }
    );
  }

  buildGov() {
    this.governratesList = this.gs.generateGov();
  }
  checkType(type) {
    this.typeOfUse = type;
  }
  setUsageValue(value) {
    this.monthlyUsage = value;
  }
  setContractualCapacityValue(value) {
    this.ContractualCapacity = value;

  }
  calculateBill() {
    this.totalPrice = 0;
    this.isCalculating = true;
    if (!this.monthlyUsage || !this.typeOfUse) {
      const message = this.translate.instant(`electricity_bill_page.required_fileds`);
      this.toastr.info(message);
      this.isCalculating = false;
      return;
    }
    if (this.electricityBillList.length) {
      if (this.typeOfUse === 'shop') {
        this.electricityBillList.map(
          eBL => {
            if (eBL.type === 'shop') {
              if (this.monthlyUsage <= 100) {
                this.totalPrice = (this.monthlyUsage * eBL.slidePrice_1) + eBL.customerServicePrice_1;
                this.customerService = eBL.customerServicePrice_1;
              } else if (this.monthlyUsage > 100 && this.monthlyUsage <= 250) {
                this.customerService = eBL.customerServicePrice_2;
                this.totalPrice = (this.monthlyUsage * eBL.slidePrice_2) + eBL.customerServicePrice_2;
              } else if (this.monthlyUsage > 250 && this.monthlyUsage <= 600) {
                this.customerService = eBL.customerServicePrice_3;
                console.log('eBL', eBL);
                console.log('monthlyUsage', this.monthlyUsage);
                this.totalPrice = (this.monthlyUsage * eBL.slidePrice_3) + eBL.customerServicePrice_3;
              } else if (this.monthlyUsage > 600 && this.monthlyUsage <= 1000) {
                this.customerService = eBL.customerServicePrice_4;
                const remian1 = this.monthlyUsage - 600;
                this.totalPrice = (600 * eBL.slidePrice_3) + (remian1 * eBL.slidePrice_4) + eBL.customerServicePrice_4;
              }
              else if (this.monthlyUsage > 1000) {
                this.customerService = eBL.customerServicePrice_5;
                this.totalPrice = (this.monthlyUsage * eBL.slidePrice_5) + eBL.customerServicePrice_5;
              }
            }
          }
        );
      } else if (this.typeOfUse === 'home') {
        this.electricityBillList.map(
          eBL => {
            if (eBL.type === 'home') {
              if (this.monthlyUsage <= 50) {
                this.totalPrice = (this.monthlyUsage * eBL.slidePrice_1) + eBL.customerServicePrice_1;
                this.customerService = eBL.customerServicePrice_1;
              } else if (this.monthlyUsage > 50 && this.monthlyUsage <= 100) {
                this.customerService = eBL.customerServicePrice_2;
                const remian = this.monthlyUsage - 50;
                this.totalPrice = (50 * eBL.slidePrice_1) + (remian * eBL.slidePrice_2) + eBL.customerServicePrice_2;
              } else if (this.monthlyUsage > 100 && this.monthlyUsage <= 200) {
                this.customerService = eBL.customerServicePrice_3;
                this.totalPrice = (this.monthlyUsage * eBL.slidePrice_3) + eBL.customerServicePrice_3;
              } else if (this.monthlyUsage > 200 && this.monthlyUsage <= 350) {
                this.customerService = eBL.customerServicePrice_4;
                const remian = this.monthlyUsage - 200;
                this.totalPrice = (200 * eBL.slidePrice_3) + (remian * eBL.slidePrice_4) + eBL.customerServicePrice_4;
              } else if (this.monthlyUsage > 350 && this.monthlyUsage <= 650) {
                this.customerService = eBL.customerServicePrice_5;
                const remian1 = this.monthlyUsage - 200;
                const remian2 = remian1 - 150;
                this.totalPrice = (200 * eBL.slidePrice_3) +
                  (150 * eBL.slidePrice_4) + (remian2 * eBL.slidePrice_5) + eBL.customerServicePrice_5;
              } else if (this.monthlyUsage > 650 && this.monthlyUsage <= 1000) {
                this.customerService = eBL.customerServicePrice_6;
                const remian1 = this.monthlyUsage - 200;
                const remian2 = remian1 - 150;
                const remian3 = remian2 - 300;
                this.totalPrice = (200 * eBL.slidePrice_3) +
                  (150 * eBL.slidePrice_4) + (300 * eBL.slidePrice_5) +
                  (remian3 * eBL.slidePrice_6) + eBL.customerServicePrice_6;
              } else if (this.monthlyUsage > 1000) {
                this.customerService = eBL.customerServicePrice_7;
                this.totalPrice = (this.monthlyUsage * eBL.slidePrice_7) + eBL.customerServicePrice_7;
              }
            }
          }
        );
      }
      else if (this.typeOfUse === 'industrial') {
        this.electricityBillList.map(
          eBL => {
            if (eBL.type === 'industrial') {
              const cc = this.ContractualCapacity * 60;
              const usage = (this.monthlyUsage * eBL.slidePrice_1) + eBL.customerServicePrice_1;
              this.customerService = eBL.customerServicePrice_1;
              this.totalPrice = cc ? (cc + usage) : usage;
            }
          }
        );
      }
      else if (this.typeOfUse === 'irrigation') {
        this.electricityBillList.map(
          eBL => {
            if (eBL.type === 'irrigation') {
              this.totalPrice = (this.monthlyUsage * eBL.slidePrice_1) + eBL.customerServicePrice_1;
              this.customerService = eBL.customerServicePrice_1;
            }
          }
        );
      }
      this.showableUsage = this.monthlyUsage;
      setTimeout(() => {
        this.isCalculating = false;
      }, 1000);
    }
  }
}
