<section>
    <div class="container">
        <div class="shadow p-3 mb-5 bg-white rounded" data-aos="fade-up" data-aos-duration="3000">
            <div class="col-md-12 text-center">
                <h1>{{'off_grid.title' | translate}}</h1>
            </div>
        </div>
    </div>
</section>
<section class="section  bg-light">
    <div class="container">
        <div class="row align-items-center mb-5">
            <div class="col-md-5 pl-md-5 mb-5 order-md-2">
                <div class="block-41">
                    <div class="block-41-subheading d-flex">
                        <div class="block-41-subheading-2">
                            <h4>{{'off_grid.title' | translate}}</h4>
                        </div>
                    </div>
                    <div class="block-41-text">
                        <p>{{'off_grid.description_1' | translate}}</p>
                        <p>{{'off_grid.description_2' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-7 order-md-1">
                <img data-aos="fade-left" src="/assets/images/off-gird-solar-system.png" alt="Image" class="img-fluid dec-img">
            </div>
        </div>
    </div>
</section>
<section class="section">
    <div class="container">
        <div class="row align-items-center mb-5">
            <div class="col-md-5 pr-md-5 mb-5">
                <div class="block-41" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div class="block-41-subheading d-flex">
                        <div class="block-41-number">01</div>
                        <div class="block-41-line align-self-center mx-2"></div>
                        <div class="block-41-subheading-2">
                            <h4>{{'off_grid.sec_1_title' | translate}}</h4>
                        </div>
                    </div>
                    <div class="block-41-text">
                        <p>{{'off_grid.sec_1_desc' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <img src="/assets/images/offgrid-pumping.jpg" alt="Image" class="img-fluid">
            </div>
        </div>
    </div>
</section>