 <div class="projects">
  <h2 class="text-center">{{ "projects_list.title" | translate }}</h2>
  <!-- <p class="projectsP text-center">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum minima sint
    mollitia odio, esse, sequi provident fuga repudiandae ducimus omnis
    doloremque laudantium nisi iste expedita eos incidunt nesciunt error eum?
  </p> -->
  <div class="container">
    <div class="row" >
      <div class="col-md-4" *ngFor="let project of projects">
        <div class="card-wrapper mb-4">
          <div class="card">
            <div class="card-img-wrapper">
              <img src="{{imgUrl}}{{project.photos}}" class="card-img-top" alt="..." />
            </div>
            <div class="card-body text-">
              <h5 *ngIf="currenLanguage === 'en'"  class="card-title">{{ project.name_en }}</h5>
              <h5 *ngIf="currenLanguage === 'ar'" class="card-title">{{ project.name_ar }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ project.date | date }}</h6>
              <span  *ngIf="currenLanguage === 'en'"  class="texts">{{project.details_en}}</span>
              <span  *ngIf="currenLanguage === 'ar'"  class="texts">{{project.details_ar}}</span>
              <div class="card-content">
                <a [routerLink]="['/project', project._id]" class="card-link">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
