import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GovernratesService {
  currentLAng = 'en';
  constructor(
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.currentLAng = event.lang;
    });
  }

  generateGov() {
    let egyptGovernorates = [];
    if (this.currentLAng === 'ar') {
      egyptGovernorates = [
        {
          id: 'القاهرة'
        },
        {
          id: 'الجيزة'
        },
        {
          id: 'الأسكندرية'
        },
        {
          id: 'الدقهلية'
        },
        {
          id: 'البحر الأحمر'
        },
        {
          id: 'البحيرة'
        },
        {
          id: 'الفيوم'
        },
        {
          id: 'الغربية'
        },
        {
          id: 'الإسماعلية'
        },
        {
          id: 'المنوفية'
        },
        {
          id: 'المنيا'
        },
        {
          id: 'القليوبية'
        },
        {
          id: 'الوادي الجديد'
        },
        {
          id: 'السويس'
        },
        {
          id: 'اسوان'
        },
        {
          id: 'اسيوط'
        },
        {
          id: 'بني سويف'
        },
        {
          id: 'بورسعيد'
        },
        {
          id: 'دمياط'
        },
        {
          id: 'الشرقية'
        },
        {
          id: 'جنوب سيناء'
        },
        {
          id: 'كفر الشيخ'
        },
        {
          id: 'مطروح'
        },
        {
          id: 'الأقصر'
        },
        {
          id: 'قنا'
        },
        {
          id: 'شمال سيناء'
        },
        {
          id: 'سوهاج'
        }
      ];
    } else {
      egyptGovernorates = [
        {
          id: 'Cairo'
        },
        {
          id: 'Giza'
        },
        {
          id: 'Alexandria'
        },
        {
          id: 'Dakahlia'
        },
        {
          id: 'Red Sea'
        },
        {
          id: 'Beheira'
        },
        {
          id: 'Fayoum'
        },
        {
          id: 'Gharbiya'
        },
        {
          id: 'Ismailia'
        },
        {
          id: 'Monofia'
        },
        {
          id: 'Minya'
        },
        {
          id: 'Qaliubiya'
        },
        {
          id: 'New Valley'
        },
        {
          id: 'Suez'
        },
        {
          id: 'Aswan'
        },
        {
          id: 'Assiut'
        },
        {
          id: 'Beni Suef'
        },
        {
          id: 'Port Said'
        },
        {
          id: 'Damietta'
        },
        {
          id: 'Sharkia'
        },
        {
          id: 'South Sinai'
        },
        {
          id: 'Kafr Al sheikh'
        },
        {
          id: 'Matrouh'
        },
        {
          id: 'Luxor'
        },
        {
          id: 'ena'
        },
        {
          id: 'North Sinai'
        },
        {
          Sohag: 'Sohag'
        }
      ];
    }
    return egyptGovernorates;
  }
}
